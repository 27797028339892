import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import buildingImageUrl from '@/assets/png/building.png'
import { PageContainer } from '@/components/common/PageContainer'
import { SectionTitle } from '@/components/common/SectionTitle'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    image: {
      flex: 'none',
      height: 375,
      width: 375,
      [breakpoints.down('md')]: {
        height: 200,
        width: 200,
        marginBottom: spacing(4),
      },
    },
    title: {
      alignSelf: 'flex-end',
      marginBottom: spacing(6),
      [breakpoints.down('md')]: {
        display: 'none',
      },
    },
    titleMobile: {
      marginBottom: spacing(4),
      [breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      [breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    description: {
      flex: 1,
      order: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 600,
      marginLeft: spacing(12),
      [breakpoints.down('md')]: {
        order: 2,
        alignItems: 'center',
        marginLeft: 0,
        padding: spacing(0, 4),
      },
    },
    motto: {
      marginBottom: spacing(2),
      fontSize: '1.875rem',
      fontWeight: typography.fontWeightBold,
      letterSpacing: '0.066667em',
      color: palette.text.primary,
      textAlign: 'left',
      [breakpoints.down('md')]: {
        marginBottom: spacing(1),
        fontSize: '1.25rem',
        letterSpacing: '0.019286em',
        textAlign: 'center',
      },
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    verse: {
      fontSize: '1.125rem',
      lineHeight: 1.556,
      letterSpacing: '0.027778em',
      color: palette.text.primary,
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0.019286em',
        textAlign: 'center',
      },
      [breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    hyphen: {
      [breakpoints.down('md')]: {
        display: 'none',
      },
    },
    reference: {
      [breakpoints.down('md')]: {
        display: 'block',
      },
    },
  })
)

export const MissionSection: FC = () => {
  const classes = useStyles()

  return (
    <PageContainer>
      <SectionTitle className={classes.titleMobile}>Our Mission</SectionTitle>
      <div className={classes.row}>
        <img
          src={buildingImageUrl}
          alt="Church building"
          className={classes.image}
        />
        <div className={classes.description}>
          <SectionTitle className={classes.title}>Our Mission</SectionTitle>
          <div className={classes.motto}>
            <q>Helping people find and follow Jesus</q>
          </div>
          <div className={classes.verse}>
            <em>
              Therefore go and make disciples of all nations, baptizing them in
              the name of the Father and of the Son and of the Holy Spirit, and
              teaching them to obey everything I have commanded you. And surely
              I am with you always, to the very end of the age.
            </em>
            <span className={classes.hyphen}> - </span>
            <strong className={classes.reference}>Matthew 28:19-20</strong>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}
