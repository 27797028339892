import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { AboutSection } from '@/components/about-us/AboutSection'
import { MissionSection } from '@/components/about-us/MissionSection'
import { PageCover } from '@/components/common/PageCover'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "mission-identity" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

const MissionIdentityPage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)

  return (
    <>
      <SEO title="Mission and Identity" />
      <PageCover title="Mission and Identity" image={image} />
      <MissionSection />
      <AboutSection />
    </>
  )
}

export default MissionIdentityPage
