import { makeStyles } from '@material-ui/core'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'
import { SectionTitle } from '@/components/common/SectionTitle'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  subsection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:last-of-type)': {
      marginBottom: spacing(16),
      [breakpoints.down('sm')]: {
        marginBottom: spacing(4),
      },
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  text: {
    maxWidth: 620,
    order: 2,
    [breakpoints.down('sm')]: {
      order: 3,
    },
  },
  title: {
    marginBottom: spacing(6),
    textAlign: 'left',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  titleMobile: {
    order: 1,
    marginBottom: spacing(2),
    textAlign: 'center',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  description: {
    fontSize: '1.125rem',
    letterSpacing: '0.027777em',
    lineHeight: 1.778,
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  image: {
    flex: 'none',
    order: 3,
    height: 360,
    width: 360,
    objectFit: 'cover',
    marginLeft: spacing(4),
    borderRadius: '50%',
    backgroundColor: '#ccc',
    [breakpoints.down('sm')]: {
      order: 2,
      height: 180,
      width: 180,
      margin: spacing(2, 0),
    },
  },
  link: {
    color: 'inherit',
  },
}))

export const AboutSection: FC = () => {
  const classes = useStyles()

  return (
    <PageContainer background="default">
      <div className={classes.subsection}>
        <SectionTitle className={classes.titleMobile}>Who We Are</SectionTitle>
        <div className={classes.text}>
          <SectionTitle className={classes.title}>Who We Are</SectionTitle>
          <p className={classes.description}>
            We are a multicultural community of Christ-following internationals
            seeking to live out our faith together in every relationship and
            dimension of our lives. We celebrate our diversity!
            <br />
            <br />
            We come from all over the world and are young and old, married and
            single, rich and poor, long-timers and short-timers, believers and
            seekers.
          </p>
        </div>
        <StaticImage
          src="../../assets/jpg/about-us-1.jpg"
          alt="Who We Are"
          className={classes.image}
        />
      </div>
      <div className={classes.subsection}>
        <SectionTitle className={classes.titleMobile}>What We Do</SectionTitle>
        <div className={classes.text}>
          <SectionTitle className={classes.title}>What We Do</SectionTitle>
          <p className={classes.description}>
            We summarize what we do with the slogan:&nbsp;
            <em>
              <strong>Helping people find and follow Jesus.</strong>
            </em>
            <br />
            <br />
            That means that we celebrate our love for God in worship, learn to
            love one another through small groups, grow to be more like Jesus
            through Bible study, serve one another and our neighbors through
            ministries, and share God’s love locally and around the world
            through missions.
          </p>
        </div>
        <StaticImage
          src="../../assets/jpg/about-us-2.jpg"
          alt="What We Do"
          className={classes.image}
        />
      </div>
      <div className={classes.subsection}>
        <SectionTitle className={classes.titleMobile}>
          What We Believe
        </SectionTitle>
        <div className={classes.text}>
          <SectionTitle className={classes.title}>What We Believe</SectionTitle>
          <p className={classes.description}>
            We are a Christ-focused, Bible-centered evangelical church, which
            means we teach and practice the traditional teachings of
            Christianity with an emphasis on God’s love shown in the life,
            death, and resurrection of Jesus Christ. The Bible—God’s written
            word—guides our lives so we devote a lot of our time to studying and
            applying its truths.
            <br />
            <br />
            As a Baptist church, we belong to the “free-church” tradition where
            each individual is challenged to respond personally to God’s gift of
            salvation through grace. We baptize (underwater) those who commit
            themselves to following Jesus in faith.
            <br />
            <br />
            Read our&nbsp;
            <Link to="/about-us/faith-statement" className={classes.link}>
              Faith Statement here
            </Link>
            .
          </p>
        </div>
        <StaticImage
          src="../../assets/jpg/about-us-3.jpg"
          alt="What We Believe"
          className={classes.image}
        />
      </div>
    </PageContainer>
  )
}
